import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
// A custom theme for this app
const theme = createMuiTheme({
 palette: {
    background: {
      default: '#rgba(0, 0, 0, 0.87)',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        //padding: '20px 10px',
        //margin: '10px',
        //backgroundColor: '#fff', // 5d737e
      },
    },
    MuiButton: {
      root: {
        //margin: '5px',
      },
    },
  },
});
export default responsiveFontSizes(theme);